function listToNodes (list, idProp, pidProp, childrenProp) {
  const childrenMap = {}
  const pidMap = {}
  for (const item of list) {
    const pid = item[pidProp]
    const group = childrenMap[pid] || (childrenMap[pid] = [])
    group.push(item)
    pidMap[item[idProp]] = pid
  }
  const nodes = []
  for (const item of list) {
    item[childrenProp] = childrenMap[item[idProp]] || []
    if (pidMap[item[pidProp]] === undefined) {
      nodes.push(item)
    }
  }
  return nodes
}

function findNode (nodes, predicate) {
  if (nodes && nodes.length) {
    for (const node of nodes) {
      if (predicate(node)) {
        return node
      }
      const child = findNode(node.children, predicate)
      if (child) {
        return child
      }
    }
  }
}

function nodeForEach (nodes, callback) {
  if (nodes && nodes.length) {
    for (const node of nodes) {
      if (callback.apply(node) === false) {
        return false
      }
      const result = nodeForEach(node.children, callback)
      if (result === false) {
        return false
      }
    }
  }
}

function listToMap (list, idProp, valueProp) {
  const map = {}
  for (const item of list) {
    map[item[idProp]] = valueProp ? item[valueProp] : item
  }
  return map
}

function parseJson (value, defaultVal) {
  try {
    return JSON.parse(value)
  } catch (e) {
    return defaultVal
  }
}

function normalizeData (obj, keepEmptyStr, doNotTrim) {
  if (!obj) {
    return obj
  }
  if (Array.isArray(obj)) {
    const result = []
    obj.forEach((item) => { result.push(normalizeData(item, keepEmptyStr, doNotTrim)) })
    return result
  }
  if (typeof obj === 'object') {
    const result = {}
    for (const prop of Object.keys(obj)) {
      const value = normalizeData(obj[prop], keepEmptyStr, doNotTrim)
      if (value === undefined || value === null || (!keepEmptyStr && value === '')) {
        continue
      }
      result[prop] = value
    }
    return result
  }
  if (typeof obj === 'string' && !doNotTrim) {
    return obj.trim()
  }
  return obj
}

function strToArr (str) {
  if (str) {
    try {
      return JSON.parse(str)
    } catch (e) {
      console.error(e)
    }
  }
  return []
}

function strToObj (str) {
  if (str) {
    try {
      return JSON.parse(str)
    } catch (e) {
      console.error(e)
    }
  }
  return {}
}

export {
  listToNodes,
  findNode,
  nodeForEach,
  listToMap,
  parseJson,
  normalizeData,
  strToArr,
  strToObj
}
