import axios from 'axios'
import { config } from '../config'
import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';
import { normalizeData } from '../utils/common-utils'

const server = axios.create(config.serverConfig)
const requestErrorMessage = '请求发送异常，请稍后重试'

function showError (message) {
  Notify.create({
    message,
    type: 'negative',
    position: 'top',
    actions: [{ icon: 'cancel', color: 'white', dense: true, round: true }],
    timeout: 2000
  })
}

let loadingCount = 0

function showLoading (config) {
  if (config.loading === false) {
    return
  }
  loadingCount++
  config._loading = true
  if (loadingCount > 0 && !Loading.isActive) {
    Loading.show({
      message: config.loadingMsg || '请求中...'
    })
  }
}

function closeLoading (config) {
  if (!config || config._loading !== true) {
    return
  }
  delete config._loading
  loadingCount--
  if (loadingCount <= 0) {
    loadingCount = 0
    if (Loading.isActive) {
      Loading.hide()
    }
  }
}

function resolveError (error) {
  const config = error.config
  if (config) {
    closeLoading(config)
    showError(requestErrorMessage)
    throw new Error(`Request error, baseURL: ${config.baseURL}, url: ${config.url}, error: ${error.message}`)
  }
  throw error
}

function getSessionToken () {
  return sessionStorage.getItem('__CONTRACT_MS_SESSION_TOKEN__')
}

function setSessionToken (config) {
  const headers = config.headers || (config.headers = {})
  const st = getSessionToken()
  if (st) {
    headers['CONTRACT-MS-ST'] = st
  }
  if (config.url === '/todo/pendingCount' || config.url === '/message/unreadCount') {
    headers['CONTRACT-MS-NO-REFRESH-TOKEN'] = 'YES'
  }
}

server.interceptors.request.use((config) => {
  config = Object.assign({}, config.serverConfig, config)
  setSessionToken(config)
  if (config.normalizeData === false) {
    showLoading(config)
    return config
  }
  if (config.data && !(config.data instanceof FormData)) {
    config.data = normalizeData(config.data, config.keepEmptyStr, config.doNotTrim)
  }
  if (config.param) {
    config.param = normalizeData(config.param, config.keepEmptyStr, config.doNotTrim)
  }
  showLoading(config)
  return config
}, resolveError)

server.interceptors.response.use(async (response) => {
  const config = response.config
  closeLoading(config)
  if (!config.resolveResponse) {
    return response
  }
  const status = response.status
  const data = response.data
  let errorMsg
  if (status === 200) {
    if (data) {
      const code = data.code
      if (code === 0) {
        return data.result
      } else if (code === 1001000) {
        const proxy = process.__global_vue__
        if (proxy) {
          if (proxy.$route.path !== '/login') {
            sessionStorage.removeItem('__CONTRACT_MS_SESSION_TOKEN__')
            proxy.$router.replace(`/login?back=${encodeURIComponent(location.href)}`)
          }
          throw new Error(data.message)
        }
      }
      errorMsg = data.message
    }
  } else {
    errorMsg = `${status} ${data ? data.error || data.message || requestErrorMessage : requestErrorMessage}`
  }
  showError(errorMsg || requestErrorMessage)
  throw new Error(`Request error, baseURL: ${config.baseURL}, path: ${config.url}, status: ${status}, data: ${JSON.stringify(data)}`)
}, resolveError)

server.showLoading = showLoading
server.closeLoading = closeLoading
server.getSessionToken = getSessionToken

export default {
  install (app) {
    app.config.globalProperties.$server = server
  }
}
