export default {
  install (app) {
    const properties = app.config.globalProperties
    properties.$message = {
      alert (message) {
        return new Promise((resolve) => {
          properties.$q.dialog({
            message,
            persistent: true
          }).onOk(resolve)
        })
      },
      confirm (message) {
        return new Promise((resolve, reject) => {
          properties.$q.dialog({
            message,
            cancel: true,
            persistent: true
          }).onOk(resolve)
            .onCancel(() => reject(new Error('Confirm cancel')))
        })
      },
      tips (message) {
        properties.$q.notify({
          message,
          position: 'top',
          actions: [{ icon: 'cancel', color: 'white', dense: true, round: true }],
          timeout: 2000
        })
      },
      tipsSuccess (message) {
        properties.$q.notify({
          message,
          icon: 'sentiment_satisfied_alt',
          color: 'positive',
          position: 'top',
          actions: [{ icon: 'cancel', color: 'white', dense: true, round: true }],
          timeout: 2000
        })
      },
      tipsWarning (message) {
        properties.$q.notify({
          message,
          icon: 'notification_important',
          color: 'warning',
          position: 'top',
          actions: [{ icon: 'cancel', color: 'white', dense: true, round: true }],
          timeout: 2000
        })
      },
      tipsError (message) {
        properties.$q.notify({
          message,
          icon: 'error',
          color: 'negative',
          position: 'top',
          actions: [{ icon: 'cancel', color: 'white', dense: true, round: true }],
          timeout: 2000
        })
      }
    }
  }
}
