function isNull (value) {
  return value === null || value === undefined
}

function isNotNull (value) {
  return !isNull(value)
}

function isEmpty (value) {
  return isNull(value) || (value.length === 0 || value.toString() === '')
}

function isNotEmpty (value) {
  return !isEmpty(value)
}

function isBlank (value) {
  return isEmpty(value) || value.toString().trim() === ''
}

function isNotBlank (value) {
  return !isBlank(value)
}

function isNumber (value) {
  return !isNaN(value)
}

function isNotNumber (value) {
  return !isNumber(value)
}

function isInteger (value) {
  return /^-?\d+$/.test(value) && +(value) >= -2147483648 && +(value) <= 2147483647
}

function isNotInteger (value) {
  return !isInteger(value)
}

function isPositiveInteger (value) {
  return /^\d+$/.test(value) && +(value) >= 0 && +(value) <= 2147483647
}

function isEmail (value) {
  return /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)
}

function qNotNull (value) {
  return isNotNull(value) || '不允许为空'
}

function qNotEmpty (value) {
  return isNotEmpty(value) || '不允许为空'
}

function qNotBlank (value) {
  return isNotBlank(value) || '不允许为空'
}

function qNumber (value) {
  return isEmpty(value) || isNumber(value) || '必须为数值'
}

function qInteger (value) {
  return isEmpty(value) || isInteger(value) || '必须为整数'
}

function qDigit (length, accuracy) {
  return (value) => {
    if (isEmpty(value)) {
      return true
    }
    if (!/^\d+(\.\d+)?$/.test(value)) {
      return '数值格式不正确'
    }
    if (value.length > length + 1) {
      return `数值长度超出限制，最大为：${length}`
    }
    const index = value.indexOf('.')
    if (index > -1) {
      if (value.substring(0, index).length > length - accuracy) {
        return `整数位数最多为：${length - accuracy}`
      }
      if (value.substring(index + 1).length > accuracy) {
        return `小数位数最多为：${accuracy}`
      }
    } else {
      if (value.length > length - accuracy) {
        return `整数位数最多为：${length - accuracy}`
      }
    }
    return true
  }
}

function qPositiveInteger (value) {
  return isEmpty(value) || isPositiveInteger(value) || '必须为正整数或0'
}

function qEmail (value) {
  return isEmpty(value) || isEmail(value) || '邮箱格式不正确'
}

function qPassword (value) {
  return isEmpty(value) || /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(value) || '密码中必须包含字母、数字、特称字符，至少8个字符'
}

export {
  isNull,
  isNotNull,
  isEmpty,
  isNotEmpty,
  isBlank,
  isNotBlank,
  isNumber,
  isNotNumber,
  isInteger,
  isNotInteger,
  isPositiveInteger,
  isEmail,
  qNotNull,
  qNotEmpty,
  qNotBlank,
  qNumber,
  qInteger,
  qDigit,
  qPositiveInteger,
  qEmail,
  qPassword
}
