let config

switch (process.env.PROFILE) {
  case 'prod':
    config = {
      serverConfig: {
        // 基地址
        baseURL: 'https://clsc.bjraee.com/bjraee-contract-ms',
        // 超时(毫秒)
        timeout: 30000,
        // 允许跨域时携带Cookie
        withCredentials: true,
        // 自动解析返回值
        resolveResponse: true,
        // 状态校验函数
        validateStatus: () => true
      }
    }
    break
  case 'dev':
    config = {
      serverConfig: {
        // 基地址
        baseURL: 'https://tms.ssp.bcste.cn/bjraee-contract-ms',
        // 超时(毫秒)
        timeout: 30000,
        // 允许跨域时携带Cookie
        withCredentials: true,
        // 自动解析返回值
        resolveResponse: true,
        // 状态校验函数
        validateStatus: () => true
      }
    }
    break
  default:
    config = {
      serverConfig: {
        // 基地址
        baseURL: 'http://127.0.0.1:9072/bjraee-contract-ms',
        // 超时(毫秒)
        timeout: 30000,
        // 允许跨域时携带Cookie
        withCredentials: true,
        // 自动解析返回值
        resolveResponse: true,
        // 状态校验函数
        validateStatus: () => true
      }
    }
}

export { config }

export default {
  install (app) {
    app.config.globalProperties.$config = config
  }
}
