import { createStore } from 'vuex'

export default createStore({
  state: {
    authMap: {}
  },
  mutations: {
    updateAuthMap (state, authMap) {
      state.authMap = authMap
    }
  },
  getters: {
    hasAuth: (state) => (code) => {
      return state.authMap[code] !== undefined
    }
  },
  actions: {
  },
  modules: {
  }
})
