import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import config from './config'
import dict from './dict'
import server from './server'
import message from './plugins/message'
import auth from './plugins/auth'

process.__global_vue__ = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(config)
  .use(dict)
  .use(server)
  .use(message)
  .use(auth)
  .mount('#app')
