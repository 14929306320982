<template>
  <q-layout view="hHh LpR fFf">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="toggleLeftDrawer"/>
        <q-btn class="ellipsis" flat no-caps no-wrap>
          <q-avatar>
            <img v-if="sysInfo.sysLogo" :src="sysInfo.sysLogo"/>
            <img v-else src="../assets/logo-mono-white.svg">
          </q-avatar>
          <q-toolbar-title>
            {{ sysInfo.sysName || 'Loading...' }}
          </q-toolbar-title>
        </q-btn>

        <q-breadcrumbs v-if="$q.screen.gt.sm && breadcrumbs" class="main-breadcrumbs">
          <q-breadcrumbs-el v-for="breadcrumb of breadcrumbs" :key="breadcrumb.code" :icon="breadcrumb.icon"
                            :label="breadcrumb.label"/>
        </q-breadcrumbs>

        <q-space/>

        <template v-if="user">
          <q-btn v-if="$q.screen.lt.sm" icon="more_vert" color="white" round flat>
            <q-menu cover auto-close>
              <q-list separator>
                <q-item clickable @click="logout" class="no-wrap">
                  <q-item-section class="text-primary text-no-wrap">退出登录</q-item-section>
                </q-item>
                <q-item clickable @click="showPasswordDialog = true" class="no-wrap">
                  <q-item-section class="text-primary text-no-wrap">修改密码</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
          <div v-else class="row items-center no-wrap">
            <q-btn round flat>
              <q-menu>
                <div class="row no-wrap q-pa-md">
                  <div class="column items-center">
                    <q-avatar size="72px">
                      <img v-if="user.avatar" :src="user.avatar">
                      <q-icon v-else name="account_circle" color="grey-5" size="72px"/>
                    </q-avatar>
                    <div class="text-subtitle1 q-mt-md q-mb-xs ellipsis">
                      {{ user.nickname || user.realName || user.username }}
                    </div>
                    <q-btn
                      color="primary"
                      label="退出登录"
                      size="sm"
                      v-close-popup
                      @click="logout"
                    />
                    <q-btn
                      class="q-mt-sm"
                      color="secondary"
                      label="修改密码"
                      size="sm"
                      v-close-popup
                      @click="showPasswordDialog = true"
                    />
                  </div>
                </div>
              </q-menu>
              <q-avatar size="26px">
                <img v-if="user.avatar" :src="user.avatar">
                <q-icon v-else name="account_circle"/>
              </q-avatar>
              <q-tooltip>账号</q-tooltip>
            </q-btn>
          </div>
        </template>

      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" :width="240" show-if-above side="left" bordered>
      <q-list class="main-menu">
        <template v-for="(menu,index) of menus" :key="index">
          <q-expansion-item
            v-if="hasChildren(menu)"
            v-model="menu.open"
            :icon="menu.icon"
            :label="menu.label"
          >
            <q-item
              v-for="(subMenu,subIndex) of menu.children" :key="subIndex"
              v-ripple
              :inset-level="0.2"
              clickable
              :active="activeMenu && subMenu.code === activeMenu.code"
              @click="clickMenu(subMenu)"
            >
              <q-item-section avatar>
                <q-icon :name="subMenu.icon"/>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ subMenu.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-expansion-item>
          <q-item
            v-else
            v-ripple
            clickable
            :active="activeMenu && menu.code === activeMenu.code"
            @click="clickMenu(menu)"
          >
            <q-item-section avatar>
              <q-icon :name="menu.icon"/>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ menu.label }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>

  <teleport to="body">
    <q-dialog v-model="showPasswordDialog" persistent>
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">修改个人密码</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <password-update-form ref="pwdUpdateFormEle"/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-right">
          <q-btn color="primary" label="确定" unelevated @click="updatePassword"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </teleport>
</template>

<script>
import { computed, getCurrentInstance, ref } from 'vue'
import { findNode, listToMap, listToNodes } from '../utils/common-utils'
import PasswordUpdateForm from './personal/PasswordUpdateForm'

export default {
  name: 'Main',
  components: {
    PasswordUpdateForm
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const sysInfo = ref({})
    const menus = ref([])
    const user = ref(null)
    const activeMenu = ref(null)
    const leftDrawerOpen = ref(false)
    const breadcrumbs = computed(() => {
      const actMenu = activeMenu.value
      const breadcrumbs = []
      if (!actMenu) {
        return breadcrumbs
      }
      for (const menu of menus.value) {
        if (menu.code === actMenu.code) {
          breadcrumbs.push(menu)
          return breadcrumbs
        }
        if (menu.children) {
          for (const subMenu of menu.children) {
            if (subMenu.code === actMenu.code) {
              breadcrumbs.push(menu)
              breadcrumbs.push(subMenu)
              return breadcrumbs
            }
          }
        }
      }
      return breadcrumbs
    })
    const showPasswordDialog = ref(false)

    async function loadSysInfo () {
      sysInfo.value = await proxy.$server.post('/sysConfig/sysInfo')
      if (sysInfo.value.sysName) {
        document.title = sysInfo.value.sysName
      }
    }

    async function loadMenus () {
      const list = await proxy.$server.post('/personal/getPersonalAuthList')
      const nodes = listToNodes(list, 'id', 'pid', 'children')
      for (const item of list) {
        item.code = item.id
        item.label = item.funcName
        item.icon = item.funcIcon
        item.path = item.viewRoute
        item.open = true
      }
      const menu = findNode(nodes, (node) => node.funcCode === 'system-menu')
      menus.value = menu ? menu.children : []
      proxy.$store.commit('updateAuthMap', listToMap(list, 'funcCode'))
      loadActiveMenu()
    }

    function findActiveMenu () {
      const route = proxy.$route.path
      for (const menu of menus.value) {
        if (menu.path === route) {
          return menu
        }
        for (const subMenu of menu.children) {
          if (subMenu.path === route) {
            menu.open = true
            return subMenu
          }
        }
      }
      return null
    }

    function loadActiveMenu () {
      let menu = findActiveMenu()
      if (menu) {
        activeMenu.value = menu
      } else {
        const route = proxy.$route.path
        if (!isInRoutes(route)) {
          menu = findFirstMenu()
          if (menu) {
            clickMenu(menu)
          }
        }
      }
    }

    function findFirstMenu () {
      for (const menu of menus.value) {
        if (menu.path && (!menu.children || !menu.children.length)) {
          return menu
        }
        for (const subMenu of menu.children) {
          if (subMenu.path) {
            return subMenu
          }
        }
      }
    }

    function isInRoutes (path) {
      const routes = proxy.$router.options.routes
      const main = findMainRoute(routes)
      for (const child of main.children || []) {
        if (child.path === path) {
          return true
        }
      }
      return false
    }

    function findMainRoute (routes) {
      for (const route of routes) {
        if (route.path === '/') {
          return route
        }
      }
      return {}
    }

    async function loadUser () {
      user.value = await proxy.$server.post('/personal/getPersonalInfo')
    }

    function toggleLeftDrawer () {
      leftDrawerOpen.value = !leftDrawerOpen.value
    }

    function hasChildren (menu) {
      return menu.children && menu.children.length
    }

    function clickMenu (menu) {
      if (menu.path) {
        proxy.$router.push(menu.path)
      }
      activeMenu.value = menu
    }

    async function logout () {
      await proxy.$message.confirm('确定要退出吗？')
      await proxy.$server.post('/logout')
      for (const storage of [localStorage, sessionStorage]) {
        storage.removeItem('__CONTRACT_MS_SESSION_TOKEN__')
        storage.removeItem('__CONTRACT_MS_SESSION_TOKEN_REMEMBER__')
        storage.removeItem('__CONTRACT_MS_SESSION_REFRESH_TOKEN__')
      }
      proxy.$router.replace('/login')
    }

    async function updatePassword () {
      const param = await proxy.$refs.pwdUpdateFormEle.validateAndGetForm()
      await proxy.$server.post('/personal/updatePassword', param)
      proxy.$message.tipsSuccess('密码已修改')
      showPasswordDialog.value = false
    }

    loadSysInfo()
    loadMenus()
    loadUser()

    return {
      sysInfo,
      menus,
      breadcrumbs,
      user,
      activeMenu,
      leftDrawerOpen,
      showPasswordDialog,
      toggleLeftDrawer,
      hasChildren,
      clickMenu,
      logout,
      updatePassword
    }
  }
}
</script>

<style lang="scss" scoped>
.main-menu {
  color : #2c3e50;

  ::v-deep .q-item {
    min-height : 48px;

    &.q-item--active {
      background : rgba(lighten($primary, 30%), 0.1);

      &:after {
        content    : "";
        position   : absolute;
        width      : 3px;
        height     : 100%;
        background : $primary;
        top        : 0;
        right      : 0;
      }
    }
  }
}

.main-breadcrumbs {
  font-size : 12px;

  ::v-deep .text-primary {
    color : inherit !important;
  }
}
</style>
