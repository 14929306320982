import { createRouter, createWebHashHistory } from 'vue-router'
import Main from '../views/Main.vue'

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/sys/sys-config-manage',
        component: () => import('../views/sys/config/SysConfigManage')
      },
      {
        path: '/sys/sys-role-manage',
        component: () => import('../views/sys/role/SysRoleManage')
      },
      {
        path: '/sys/sys-user-manage',
        component: () => import('../views/sys/user/SysUserManage')
      },
      {
        path: '/sys/operation-log-manage',
        component: () => import('../views/sys/log/OperationLogManage')
      },
      {
        path: '/contract/contract-template-manage',
        component: () => import('../views/contract/template/ContractTemplateManage')
      },
      {
        path: '/contract/contract-data-manage',
        component: () => import('../views/contract/data/ContractDataManage')
      },
      {
        path: '/contract/contract-global-data-manage',
        component: () => import('../views/contract/data/ContractGlobalDataManage')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('../views/Login')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
