<template>
  <q-form ref="formEle" class="q-gutter-md">
    <q-input
      v-model="form.oldPassword"
      :rules="rules.oldPassword"
      type="password"
      label-slot
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>输入当前密码</span>
      </template>
    </q-input>
    <q-input
      v-model="form.newPassword"
      :rules="rules.newPassword"
      type="password"
      label-slot
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>设置新密码</span>
      </template>
    </q-input>
    <q-input
      v-model="form.password2"
      :rules="rules.password2"
      type="password"
      label-slot
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>再次输入新密码</span>
      </template>
    </q-input>
  </q-form>
</template>

<script>
import { getCurrentInstance, nextTick, ref } from 'vue'
import { qNotBlank, qPassword } from '../../utils/validate-utils'

export default {
  setup (props) {
    const { proxy } = getCurrentInstance()
    const form = ref({
      oldPassword: null,
      newPassword: null,
      password2: null
    })
    const rules = ref({
      oldPassword: [qNotBlank],
      newPassword: [qNotBlank, qPassword],
      password2: [qNotBlank, (v) => {
        return v === form.value.newPassword || '两次输入的密码不一致'
      }]
    })

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    return {
      form,
      rules,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm
    }
  }
}
</script>
