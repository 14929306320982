export default {
  install (app) {
    const properties = app.config.globalProperties
    properties.$hasAuth = function (auth) {
      return properties.$store &&
        properties.$store.getters &&
        typeof properties.$store.getters.hasAuth === 'function' &&
        properties.$store.getters.hasAuth(auth)
    }
  }
}
