const dict = {
  // 是否启用
  enable: [
    { label: '启用', value: true },
    { label: '禁用', value: false }
  ],
  // 性别
  gender: [
    { label: '男', value: 1 },
    { label: '女', value: 2 }
  ],
  // 是否
  yesNo: [
    { label: '是', value: true },
    { label: '否', value: false }
  ],
  // 有无
  hasNot: [
    { label: '有', value: true },
    { label: '无', value: false }
  ],
  // 系统配置类型
  sysConfigType: [
    { label: '目录', value: 0 },
    { label: '纯文本', value: 1 },
    { label: 'JSON', value: 2 }
  ],
  // 字段类型
  fieldType: [
    { label: '字符串', value: 2 },
    { label: '整数', value: 1 },
    { label: '数值', value: 3 },
    { label: '日期', value: 4 },
    { label: '时间', value: 5 },
    { label: '日期时间', value: 6 }
  ],
  // 操作类型
  operationType: [
    { label: '登录系统', value: '登录系统' },
    { label: '退出系统', value: '退出系统' },
    { label: '创建系统配置', value: '创建系统配置' },
    { label: '编辑系统配置', value: '编辑系统配置' },
    { label: '删除系统配置', value: '删除系统配置' },
    { label: '新增用户', value: '新增用户' },
    { label: '编辑用户', value: '编辑用户' },
    { label: '删除用户', value: '删除用户' },
    { label: '重置用户密码', value: '重置用户密码' },
    { label: '解锁用户', value: '解锁用户' },
    { label: '新增角色', value: '新增角色' },
    { label: '编辑角色', value: '编辑角色' },
    { label: '删除角色', value: '删除角色' },
    { label: '设置角色权限', value: '设置角色权限' }
  ]
}

const dictLabel = (val, dictOptions, defaultValue) => {
  const item = dictOptions.find((v) => v.value === val)
  return item ? item.label : (val === null || val === undefined ? defaultValue : val)
}

const dictFormat = (val, map) => {
  return map[val] || val
}

const dictFormatter = (dictOptions) => {
  if (typeof dictOptions === 'string') {
    dictOptions = dict[dictOptions]
  }
  const map = {}
  dictOptions.forEach((item) => {
    map[item.value] = item.label
  })
  return (value) => dictFormat(value, map)
}

export { dict }

export default {
  install (app) {
    const properties = app.config.globalProperties
    properties.$dict = dict
    properties.$dictValue = dictFormat
    properties.$dictLabel = dictLabel
    properties.$dictFormatter = dictFormatter
  }
}
